/* DEPARTMENT */
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST';
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS';
export const DEPARTMENT_LIST_FAILED = 'DEPARTMENT_LIST_FAILED';

export const DEPARTMENT_ADD = 'DEPARTMENT_ADD';
export const DEPARTMENT_ADD_SUCCESS = 'DEPARTMENT_ADD_SUCCESS';
export const DEPARTMENT_ADD_FAILED = 'DEPARTMENTADD_ADD_FAILED';

export const DEPARTMENT_UPDATE = 'DEPARTMENT_UPDATE';
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS';
export const DEPARTMENT_UPDATE_FAILED = 'DEPARTMENT_UPDATE_FAILED';

export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE';
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS';
export const DEPARTMENT_DELETE_FAILED = 'DEPARTMENTADD_DELETE_FAILED';
/* DAILYACTIVITY */
export const DAILYACTIVITY_LIST = 'DAILYACTIVITY_LIST';
export const DAILYACTIVITY_LIST_SUCCESS = 'DAILYACTIVITY_LIST_SUCCESS';
export const DAILYACTIVITY_LIST_FAILED = 'DAILYACTIVITY_LIST_FAILED';

export const DAILYACTIVITY_ADD = 'DAILYACTIVITY_ADD';
export const DAILYACTIVITY_ADD_SUCCESS = 'DAILYACTIVITY_ADD_SUCCESS';
export const DAILYACTIVITY_ADD_FAILED = 'DAILYACTIVITY_ADD_FAILED';

export const DAILYJOBDES_ADD = 'DAILYJOBDES_ADD';
export const DAILYJOBDES_ADD_SUCCESS = 'DAILYJOBDES_ADD_SUCCESS';
export const DAILYJOBDES_ADD_FAILED = 'DAILYJOBDES_ADD_FAILED';

export const DAILYACTIVITY_UPDATE = 'DAILYACTIVITY_UPDATE';
export const DAILYACTIVITY_UPDATE_SUCCESS = 'DAILYACTIVITY_UPDATE_SUCCESS';
export const DAILYACTIVITY_UPDATE_FAILED = 'DAILYACTIVITY_UPDATE_FAILED';

export const DAILYACTIVITY_DELETE = 'DAILYACTIVITY_DELETE';
export const DAILYACTIVITY_DELETE_SUCCESS = 'DAILYACTIVITY_DELETE_SUCCESS';
export const DAILYACTIVITY_DELETE_FAILED = 'DAILYACTIVITYADD_DELETE_FAILED';
/* WEEKLYACTIVITY */
export const WEEKLYACTIVITY_LIST = 'WEEKLYACTIVITY_LIST';
export const WEEKLYACTIVITY_LIST_SUCCESS = 'WEEKLYACTIVITY_LIST_SUCCESS';
export const WEEKLYACTIVITY_LIST_FAILED = 'WEEKLYACTIVITY_LIST_FAILED';

export const WEEKLYACTIVITY_ADD = 'WEEKLYACTIVITY_ADD';
export const WEEKLYACTIVITY_ADD_SUCCESS = 'WEEKLYACTIVITY_ADD_SUCCESS';
export const WEEKLYACTIVITY_ADD_FAILED = 'WEEKLYACTIVITY_ADD_FAILED';

export const WEEKLYJOBDES_ADD = 'WEEKLYJOBDES_ADD';
export const WEEKLYJOBDES_ADD_SUCCESS = 'WEEKLYJOBDES_ADD_SUCCESS';
export const WEEKLYJOBDES_ADD_FAILED = 'WEEKLYJOBDES_ADD_FAILED';

export const WEEKLYACTIVITY_UPDATE = 'WEEKLYACTIVITY_UPDATE';
export const WEEKLYACTIVITY_UPDATE_SUCCESS = 'WEEKLYACTIVITY_UPDATE_SUCCESS';
export const WEEKLYACTIVITY_UPDATE_FAILED = 'WEEKLYACTIVITY_UPDATE_FAILED';

export const WEEKLYACTIVITY_DELETE = 'WEEKLYACTIVITY_DELETE';
export const WEEKLYACTIVITY_DELETE_SUCCESS = 'WEEKLYACTIVITY_DELETE_SUCCESS';
export const WEEKLYACTIVITY_DELETE_FAILED = 'WEEKLYACTIVITYADD_DELETE_FAILED';
/* MONTHLYACTIVITY */
export const MONTHLYACTIVITY_LIST = 'MONTHLYACTIVITY_LIST';
export const MONTHLYACTIVITY_LIST_SUCCESS = 'MONTHLYACTIVITY_LIST_SUCCESS';
export const MONTHLYACTIVITY_LIST_FAILED = 'MONTHLYACTIVITY_LIST_FAILED';

export const MONTHLYACTIVITY_ADD = 'MONTHLYACTIVITY_ADD';
export const MONTHLYACTIVITY_ADD_SUCCESS = 'MONTHLYACTIVITY_ADD_SUCCESS';
export const MONTHLYACTIVITY_ADD_FAILED = 'MONTHLYACTIVITY_ADD_FAILED';

export const MONTHLYJOBDES_ADD = 'MONTHLYJOBDES_ADD';
export const MONTHLYJOBDES_ADD_SUCCESS = 'MONTHLYJOBDES_ADD_SUCCESS';
export const MONTHLYJOBDES_ADD_FAILED = 'MONTHLYJOBDES_ADD_FAILED';

export const MONTHLYACTIVITY_UPDATE = 'MONTHLYACTIVITY_UPDATE';
export const MONTHLYACTIVITY_UPDATE_SUCCESS = 'MONTHLYACTIVITY_UPDATE_SUCCESS';
export const MONTHLYACTIVITY_UPDATE_FAILED = 'MONTHLYACTIVITY_UPDATE_FAILED';

export const MONTHLYACTIVITY_DELETE = 'MONTHLYACTIVITY_DELETE';
export const MONTHLYACTIVITY_DELETE_SUCCESS = 'MONTHLYACTIVITY_DELETE_SUCCESS';
export const MONTHLYACTIVITY_DELETE_FAILED = 'MONTHLYACTIVITYADD_DELETE_FAILED';

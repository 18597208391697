/* TEAM */
export const ADMINHASH_LIST = 'ADMINHASH_LIST';
export const ADMINHASH_LIST_SUCCESS = 'ADMINHASH_LIST_SUCCESS';
export const ADMINHASH_LIST_FAILED = 'ADMINHASH_LIST_FAILED';

export const ADMINHASH_ADD = 'ADMINHASH_ADD';
export const ADMINHASH_ADD_SUCCESS = 'ADMINHASH_ADD_SUCCESS';
export const ADMINHASH_ADD_FAILED = 'ADMINHASH_ADD_FAILED';

export const ADMINHASH_UPDATE = 'ADMINHASH_UPDATE';
export const ADMINHASH_UPDATE_SUCCESS = 'ADMINHASH_UPDATE_SUCCESS';
export const ADMINHASH_UPDATE_FAILED = 'ADMINHASH_UPDATE_FAILED';

export const ADMINHASH_DELETE = 'ADMINHASH_DELETE';
export const ADMINHASH_DELETE_SUCCESS = 'ADMINHASH_DELETE_SUCCESS';
export const ADMINHASH_DELETE_FAILED = 'ADMINHASH_DELETE_FAILED';



export const ADMINHASH_SHOW = 'ADMINHASH_SHOW';
export const ADMINHASH_SHOW_SUCCESS = 'ADMINHASH_SHOW_SUCCESS';
export const ADMINHASH_SHOW_FAILED = 'ADMINHASH_SHOW_FAILED';




export const  TOPICHASH_LIST='TOPICHASH_LIST';
export const  TOPICHASH_LIST_SUCCESS='TOPICHASH_LIST_SUCCESS';
export const  TOPICHASH_LIST_FAILED='TOPICHASH_LIST_FAILED';
export const  TOPICHASH_ADD='TOPICHASH_ADD';
export const  TOPICHASH_ADD_SUCCESS='TOPICHASH_ADD_SUCCESS';
export const  TOPICHASH_ADD_FAILED='TOPICHASH_ADD_FAILED';
export const  TOPICHASH_UPDATE='TOPICHASH_UPDATE';
export const  TOPICHASH_UPDATE_SUCCESS='TOPICHASH_UPDATE_SUCCESS';
export const  TOPICHASH_UPDATE_FAILED='TOPICHASH_UPDATE_FAILED';
export const  TOPICHASH_DELETE='TOPICHASH_DELETE';
export const  TOPICHASH_DELETE_SUCCESS='TOPICHASH_DELETE_SUCCESS';
export const  TOPICHASH_DELETE_FAILED='TOPICHASH_DELETE_FAILED';
export const  TOPICHASH_SHOW='TOPICHASH_SHOW';
export const  TOPICHASH_SHOW_SUCCESS='TOPICHASH_SHOW_SUCCESS';
export const  TOPICHASH_SHOW_FAILED='TOPICHASH_SHOW_FAILED';


